<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="filterByLastVersion"
		></pui-datatable>
	</div>
</template>

<script>
import shipActions from './ShipActions';

export default {
	name: 'ship-grid',
	components: {},
	data() {
		return {
			modelName: 'ship',
			actions: shipActions.gridactions,
			modelColumnDefs: {
				creationdatetime: { withoutHours: true },
				downdatetime: { withoutHours: true }
			}
		};
	},
	computed: {
		filterByLastVersion() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'nextversionid', op: 'nu', data: null }]
			};
		}
	}
};
</script>
